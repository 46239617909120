@font-face {
    font-family: "Impact";
    src: url("../../fonts/impact.ttf") format("truetype"), 
    url("../../fonts/impact.woff2") format("woff2"),
    url("../../fonts/impact.woff") format("woff");
}
@font-face {
  font-family: "FuturaPTbook";
  src: url("../../fonts/FuturaPTBook.otf") format("opentype"),
    url("../../fonts/FuturaPTBook.ttf") format("truetype"), 
    url("../../fonts/FuturaPTBook.woff2") format("woff2"),
    url("../../fonts/FuturaPTBook.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "FuturaPT";
  src: url("../../fonts/FuturaPTMedium.otf") format("opentype"),
    url("../../fonts/FuturaPTMedium.ttf") format("truetype"), 
    url("../../fonts/FuturaPTMedium.woff2") format("woff2"),
    url("../../fonts/FuturaPTMedium.woff") format("woff");  
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Neon";
  src: 
    url("../../fonts/neon.ttf") format("truetype"),
    url("../../fonts/neon.woff2") format("woff2"),
    url("../../fonts/neon.woff") format("woff");
  font-weight: normal;
}
html {
  scroll-snap-type: y;
  overflow-x: hidden;
  }
/*scroll-snap-type: y mandatory;*/
.wrapper{
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.firstSlide{
  display: flex;
  flex-direction: row;
}

.secondSlide{  
  display: grid;
}

.firstSlide, .secondSlide, .thirdSlide, .fourthSlide, .fifthSlide, .sixthSlide, .seventhSlide, .eighthSlide, .ninthSlide{
  height: 100vh;
  /*scroll-snap-align: center;*/
}

.project{
  margin: 0 auto;
  width:80vw;
}

.project h4{
  font-size:24px;
  line-height:28px;
  font-weight:500;
  font-family: FuturaPT, sans-serif;
  font-kerning:2px;
  margin-top:0.5rem;
}
@media only screen and (min-width: 782px) {
  .project h4{
    font-size:32px;
    line-height:40px;
    margin-top:0;
  }
}
.project h5{
  font-size:20px;
  line-height:24px;
  color:hsla(0, 0%, 100%, 0.7);
  font-weight:400;
  font-family: FuturaPTbook, sans-serif;
  margin-top:0.5rem;
}
@media only screen and (min-width: 782px) {
  .project h5{
    margin-top:0;
  }
}
.project .description {
  width:100%;
  margin-left: 0;
}
@media only screen and (min-width: 782px) {
  .project{
    width:60vw;
  }
  .boz.project {
    width: 69vw;
  }
  .project .description {
    width:50vw;
    margin-left:-7px;
  }
}
@media only screen and (min-width: 782px) {
  .program, .hzt{
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    grid-row-gap: 16px;
    grid-column-gap: 16px;
  }
}
.program{width:80vw;}
.projecttext{display:inline-block;  font-family: Impact, Arial, sans-serif;}
.pioniers > .projecttext{margin-top:5vh;}
@media only screen and (min-width: 782px) {
  .pioniers > .projecttext{margin-top:16vh;}
}
div.div-block-2, div.full-image{
  position:absolute;
  top:0;
  left:0;
  z-index:-1;
}
div.full-image{
  width: 100%;
  height: 100%;
  background-image: url(../../images/croppeddestort.gif);
  background-position: 0px 0px;
  background-size: cover;
  opacity: 0.3;
  }
.image-program{
  background-image: url(../../images/programmagids.gif);
}
.image-right{
  display:inline-grid;
  width:30vw;
  height:70vh;
  margin: 10vh 0px 0px 32px;
  position: absolute;
  right: 10vw;
  z-index:-1;
  background-size:contain;
  background-repeat:no-repeat;
}
.hzt > .image-right div{
  margin-left:20%;
  margin-top:0;
  width:60%;
  background-image: url(../../images/purple-yellow-lines.jpeg);
  background-size:cover;
  background-repeat:no-repeat;
  background-position:center center;
}
@media only screen and (min-width: 782px) {
    .hzt > .image-right div{
      margin-left:16vw;
      margin-top:12vh;
      width:28vw;
    }
}
.hzt > .image-right{
  position:relative;
  width:100%;
  right:0;
  margin:1rem 0 0 0;
  height:32vh;
  background-color: #fcc318;
  border:4px solid #665673;
}
@media only screen and (min-width: 782px) {
  .hzt > .image-right{
    position:absolute;
    width:30vw;
    right:20vw;
    margin-top:10vh 0px 0px 32px;
    height:70vh;
    background-color: rgba(100, 42, 131, 0.6);
    border:none;
  }
}
.overlap > .image-right{
  background-repeat: no-repeat;
  background-size:cover;
  opacity:0.4;
  height:100vh;
  margin-top:0;
  right:0;
}
.animate > .image-right{
  background-image: url('../../images/lollygag.gif');
  width:100vw;
}
@media only screen and (min-width: 782px) {
  .animate > .image-right{
    width:30vw;
  }
}
.stort > .image-right {
  background-image: url(../../images/croppeddestort.gif);
  top:auto;
  width:100vw;
}
 .image-background{
  background-image: url(../../images/animatie-dustin-long.gif);
  left:0;
  top:auto;
  width:100vw;
  height:50vh;
  position: absolute;
  z-index:-1;
  background-size:contain;
  background-repeat:no-repeat;
}
.graphic .image-background{
  background-image: url(../../images/animation-bike3-final.gif);
  opacity: 0.9;
  background-size:contain;
  width: 100vw;
  right: auto;
  left: 0;
  margin-top: 2rem;
  transform: scaleX(-1);
}
@media only screen and (min-width: 782px) {
  .graphic .image-background{
    right: 0;
    left: auto;
    margin-top: 4rem;
    transform: scaleX(1);
    width:80vw;
  }
}
@media only screen and (min-width: 782px) {
  .stort > .image-right {
    margin-top:0;
    /*top:0;
    bottom:14vh;*/
  }
  .image-background{
    position: absolute;
    height:calc(100vh - 4rem);
    background-size:cover;
    display:inline-grid;
  }
}
.baltan > .image-right{
  background-image: url('../../images/baltan.gif');
}
.baltan.pioniers > .image-right{
  background-image: url('../../images/list-view-desktop-min.jpg');
  width:43rem;
}

.boz > .image-right{
  background-image:none;
  opacity:1;

}
.boz > .image-right.small{
  background-image: url('../../images/boz-animation.gif');
  background-size:contain;
  background-position:center;
  height:50vh;
  background-color:#acc8fe;
  margin:1rem auto 0 10vw;
}

#signalen .boz > .image-right.small{
  background-image: url('../../images/locatie-kaart.gif');
}



@media only screen and (min-width: 782px) {
  .boz > .image-right{
    background-image:none;
    opacity:1;
    position:absolute;
  }
  .boz > .image-right.small{
    background-size:contain;
    height:60vh;
    background-color:unset;
    right:0;
    margin:14vh 0px 0px 32px;
  }
}
#dustin .boz .image-right{
  background-color:inherit;
}
#dustin .boz > .image-right.small{
  background-image: url('../../images/dustin-cover-retina.jpg');
}






#globe {
  background-color:red;
  width: 300px;
  height:300px;
  transition: all .5s ease;
}




.ball {
  width: 240px;
  height: 240px;
  margin: 50px auto 0;
  border-radius: 50%;
  margin:20px;
  z-index:-1;
    -webkit-box-shadow: 3px 3px 5px 6px rgba(0, 0, 0, 0.2); 
    -moz-box-shadow:    3px 3px 5px 6px rgba(0, 0, 0, 0.2);  
    box-shadow:         3px 3px 5px 6px rgba(0, 0, 0, 0.2);
}
.bigger {
  width:420px;
  height:420px;
  background-image: radial-gradient(45px 45px 45deg, circle cover, #00AAFF 0%, blue 100%, blue 95%);
  background-image: -webkit-radial-gradient(190px 190px, circle cover, #00AAFF, #00AAFF, blue);

  -webkit-animation: spin 200s ease infinite;
  -moz-animation: spin 900s ease infinite;
  -o-animation: spin 200s ease infinite;
  animation: spin 20s ease infinite;
  animation-timing-function: linear;
  position:relative;
}

span.rotate {
  display:inline-block;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg); 
    -moz-transform: rotate(90deg); 
    vertical-align:middle;
    letter-spacing:4px;
}

.eye{
   position: absolute;
   background: white;
   border-radius: 50%;
   height: 60px;
   width: 60px;
   top: 20%;
   left: 40%;
   margin: -30px 0px 0px -30px;
 }

.eyeright{
   position: absolute;
   background: white;
   border-radius: 50%;
   height: 60px;
   width: 60px;
   top: 20%;
   left: 60%;
   margin: -30px 0px 0px -30px;
 }
 .pupil{
   position: absolute;
   background: black;
   border-radius: 50%;
   height: 40px;
   width: 40px;

   top: 40%;
   left: 60%;
   margin: -20px 0px 0px -20px;
 }

@-webkit-keyframes spin {
  from {transform: rotate(0deg);}
  to{transform: rotate(360deg);}
}
@-moz-keyframes spin {
  from {transform: rotate(0deg);}
  to{transform: rotate(360deg);}
}
@-o-keyframes spin {
  from {transform: rotate(0deg);}
  to{transform: rotate(360deg);}
}

@keyframes spin{
  from {transform: rotate(0deg);}
  to{transform: rotate(360deg);}
}





.image-right.small{
  opacity:1;
  right:10vw;
  width: 80vw;
  height: 32vh;
  margin-top:1rem;
  position:relative;
}
@media only screen and (min-width: 782px) {
  .image-right.small{
    right:12vw;
    width: 30vw;
    height: 50vh;
    margin-top:10vh;
    position:absolute;
  }
  .baltan.pioniers > .image-right{
  margin-top:28vh;
  margin-right:4vw;
  height:90vh;
  }
}
.stort > .image-right.small{
  margin-top:1rem;
}
@media only screen and (min-width: 782px) {
  .stort > .image-right.small{
    margin-top:14vh;
  }
}
.baltan > .image-right.small, .program > .image-right.small{
  right: 0;
  width: 80vw;
  right: 10vw;
}
@media only screen and (min-width: 782px) {
  .baltan > .image-right.small, .program > .image-right.small{
      width: 38vw;
      right: 0;
  }
}
.animate > .image-right.small {
  top:auto;
}
@media only screen and (min-width: 782px) {
  /*.animate > .image-right.small {
    margin-top: -11em;
  }*/
}
.projectmarquee{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10vh 0 0;
  --marquee-width: 100vw;
  --offset: 20vw;
  --move-initial: calc(-25% + var(--offset));
  --move-final: calc(-50% + var(--offset));
  --item-font-size: 12vw;
  counter-reset: menu;
  text-transform:uppercase
}
.program .projectmarquee{
  --item-font-size: 8vw;
}
/*.program .projectmarquee, .hzt .projectmarquee {
  height:21vw;
}
.baltan .projectmarquee{
  height:10vw;
}*/
.program .projectmarquee, .hzt .projectmarquee {
  height:auto;
}
.baltan .projectmarquee{
  height:auto;
}

.projectmarquee .menu__item {
  z-index:2;
  cursor: default;
  position: relative;
  padding: 0;
}

.projectmarquee .menu__item-link {
  display: inline-block;
  cursor: pointer;
  position: relative;
  -webkit-text-stroke: 1px #FFF;
  text-stroke: 1px #FFF;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  color: transparent;
  transition: opacity 0.4s;
  text-decoration:none;
}
@media only screen and (min-width: 782px) {
  .projectmarquee .menu__item-link {
    -webkit-text-stroke: 3px #FFF;
    text-stroke: 3px #FFF;
  }
}

.projectmarquee .menu__item-link::before {
  all: initial;
  font-family: Impact, sans-serif;
  counter-increment: menu;
  content:  counter(menu);
  position: absolute;
  bottom: 60%;
  left: 0;
  pointer-events: none;
  visibility:hidden;
}

.projectmarquee .menu__item-link:hover {
  transition-duration: 0.1s;
  opacity: 0;
}
/*
.projecttext:hover + .image-right, .projecttext:hover  ~  .small{
  transition-duration: 0.4s;
  opacity:0 !important;
}*/
.hide{opacity:0 !important;}
.projectmarquee:hover  ~  .div-block-2{
  transition-duration: 0.4s;
  opacity:0;
}
.projectmarquee .marquee {
  position: absolute;
  top: 0;
  left: 0;
  width: var(--marquee-width);
  overflow: hidden;
  pointer-events: none;
  mix-blend-mode: color-burn;
  margin-left:-20vw;
}
.projectmarquee .marquee__inner {
  width: fit-content;
  display: flex;
  position: relative;
  transform: translate3d(var(--move-initial), 0, 0);
  animation: marqueeproject 2.5s linear infinite;
  animation-play-state: paused;
  opacity: 0;
  transition: opacity 0.1s;
}
.projectmarquee .menu__item-link:hover ~ .marquee .marquee__inner {
  animation-play-state: running;
  opacity: 1;
  transition-duration: 0.4s;
}

.projectmarquee .marquee span {
  text-align: center;
  padding:0 2.4vw;
}

.projectmarquee .menu__item-link,
.projectmarquee .marquee span {
  white-space: nowrap;
  font-size: 48px;
  margin-left:0px;
  font-weight: 400;
  line-height: 1.05;
  text-rendering: optimizeLegibility;
}
@media only screen and (min-width: 782px) {
  .projectmarquee .menu__item-link,
  .projectmarquee .marquee span {
    margin-left:-9px;
    font-size: var(--item-font-size);
  }
}

.projectmarquee .marquee span {
  font-style: italic;
  text-transform:uppercase;
  text-align:left;
}

@keyframes marqueeproject {
  0% {
    transform: translate3d(var(--move-initial), 0, 0);
  }

  100% {
    transform: translate3d(var(--move-final), 0, 0);
  }
}

.open .image-right{
  display:none;
}
@media only screen and (min-width: 782px) {
  .container .image-big img{
    margin-top: 2rem;
  }
}
.program-guide-page .container .image-big img {
  margin-top: 0rem;
}

/*
@media (max-width: 767px) {
  .desktop {
    display:none;
  }
  .mobile{
    display:block;
  }
  .project {
    width:80vw;
  }
  .project .description {
    width: 100%;
  }
  .program, .hzt {
    display:block;
  }
  .program .projectmarquee, .hzt .projectmarquee {
    height: auto;
  }
  .projectmarquee .menu__item-link, .projectmarquee .marquee span {
    margin-left: 0px;
    line-height:1.2;
  }
  .baltan .projectmarquee .menu__item-link{
    font-size:10vw;
  }
  .program .menu__item-link, .hzt .menu__item-lin{
    font-size:12vw;
  }
  .image-right.small{
    margin:0.5em 0 0 0;
    left:0;
    width:100%;
    position:relative;
    height:52vw;
    transition-duration: 0.4s;
  }
  .stort > .image-right.small{
    margin-top:0.5em;
  }
  .baltan > .image-right.small, .program > .image-right.small {
    width:100%;
    right:auto;
    left:0;
  }
  .animate .image-right{
    right:auto;
    left:0;
    width:100%;
    margin:0 0;
  }
  .animate > .image-right.small {
    margin-top:6vh;
    }
  .hzt .image-right{
    right: auto;
    width: 90%;
    position: relative;
    margin: 1em 0 0 10%;
    height:52vw;
  }
  .hzt > .image-right img {
    margin-left: -10%;
    margin-top: 7vh;
    width: 55%;
  }
}*/