.pagination {
  position: fixed;
  left: 50%;
  bottom: 20px;
  z-index: 999;
  display: block;
  margin-bottom: 0px;
  margin-left: -130px;
  padding-left: 0px;
  font-size: 16px;
}
.pagination li {
  display: inline-block;
}
.pagination a:after {
    -webkit-transition: box-shadow 0.5s ease;
    transition: box-shadow 0.5s ease;
    width: 10px;
    height: 10px;
    display: block;
    border: 1px solid;
    border-radius: 50%;
    content: '';
    position: absolute;
    margin: auto;
    top: 0;
    right: 4px;
    bottom: 1px;
}
.pagination a.active:after {
    box-shadow: inset 0 0 0 5px;
}
.link-block {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  margin-bottom: 5px;
  padding: 4px;
  color: #fff;
}
.icon{
  position: absolute;
  left: 50%;
  left: calc(50% - 8px);
  bottom: 4px;
}
.true{
  opacity:1;
}
.false{
  opacity:0 !important;
}

@media only screen and (min-width: 782px) {
  .pagination {
    position: fixed;
    left: 4px;
    bottom:auto;
    top: 40%;
    z-index: 999;
    display: block;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-left: 0px;
    font-size: 16px;
  }
  .pagination li {
    display: block;
  }
  .icon{
    display:none;
  }
}