html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
[data-theme="dark"] body.light {
    color:red;
    background-color:blue;
}

.clearfix:after {
  clear: both;
  content: " ";
  display: block;
  font-size: 0;
  height: 0;
  visibility: hidden;
}
.clearfix { display: inline-block; }
* html .clearfix { height: 1%; }
.clearfix { display: block; }

@font-face {
    font-family: "Anton";
    src: url("../../fonts/impact.ttf") format("truetype"), 
    url("../../fonts/impact.woff2") format("woff2"),
    url("../../fonts/impact.woff") format("woff");
}
@font-face {
    font-family: "FuturaPTbook";
    src: url("../../fonts/FuturaPTBook.ttf") format("truetype"), 
    url("../../fonts/FuturaPTBook.woff2") format("woff2"),
    url("../../fonts/FuturaPTBook.woff") format("woff");
}
body{
    background-color:#000;
    color:#FFFFFF;
}
body.light{
    background-color:#efefef;
}
nav{
    position:relative;
}
@media only screen and (min-width: 782px) {
    nav{
       position:fixed;  
    }   
}
.container{
    width: 88%;
    margin-right: 6%;
    margin-left: 6%;
}
@media only screen and (min-width: 782px) {
    .container{
        width: 70%;
        margin-right: 15%;
        margin-left: 15%;
    }  
}
h1, h3, h4, p.intro, .next{
    font-family: Anton, sans-serif;
    color: #fff;
}
p, p.intro-small, .description-big, .project-button{
    font-family: FuturaPTbook, sans-serif;
    color:#fff;
}
.light h1, .light h3, .light h4, .light p.intro, .light .next, .light p, .light p.intro-small, .light .description-big, .light .project-button {
    color:#000000;
}
h1{  
    position: static;
    display: block;
    width: auto;
    min-width: auto;
    margin-left: -4px;
    padding-top: 38px;
    float: none;
    clear: none;
    font-size: 54px;
    line-height: 54px;
    font-weight: 400;
    text-align: left;
    -o-object-fit: fill;
    object-fit: fill;
    text-transform:uppercase;
}
@media only screen and (min-width: 782px) {
    h1{
        padding-top: 84px;
        font-size: 180px;
        line-height: 180px;   
        font-size:8vw;
        line-height:8vw;
    }   
}
p.intro{
    margin-top: 0.2rem;
    font-size: 18px;
    line-height: 26px;
    width: 100%;
    color: hsla(0, 0%, 100%, 0.9);
}
@media only screen and (min-width: 782px) {
    p.intro{
        margin-top: 0;
        font-size: 28px;
        line-height: 44px;
        width: 90%;
        margin-right: 10%;   
    }   
}
p.intro-small{
    width: 100%;
    margin-top: 12px;
    margin-bottom: 10px;
    color: hsla(0, 0%, 100%, 0.7);
    font-size: 20px;
    line-height: 24px;
}
@media only screen and (min-width: 782px) {
    p.intro-small{
        width: 90%;
        margin-right: 10%;
        font-size: 24px;
        line-height: 28px; 
        margin-bottom:2rem;
    }   
}

ul.list{
    margin-top:10px;
    padding-left:40px;
    margin-bottom:0;
}
.squares{
    margin-top: 2rem;
    display: block;
}
.square{ 
    min-height: 400px;
    width: 100%;
}
.pioniers .square{
    min-height:auto;
}
.pioniers .mobile{
    display:none;
}
@media only screen and (min-width: 782px) {
        .pioniers .mobile{
            display:block;
        }
}
.de-stort-page .square{
    background-image: url(../../images/croppeddestort.gif);    
}
.square-small{
    margin-top: 1rem;
    min-height: 320px;
    width: 100%;    
}
.de-stort-page .square-small {
    background-image: url(../../images/croppeddestort.gif);
    background-size: 10%;
    background-repeat: repeat;
}
.boz .square, .hzt-page .square{
    background-image:none;
    margin-bottom:2rem;
}
.graphicanimation h1{
    display:none;
}
.graphicanimation .container{
    padding-top:2rem;
}
@media only screen and (min-width: 782px) {
    .graphicanimation .container{
        padding-top:4rem;
    }
}
.work-wrapper{
    margin: 4rem 0 4rem 0;
}
.graphicanimation .image-big img{
    width:70%;
    margin-left:15%;
}

.graphicanimations .img-big img{
    width:80%;
}
.graphicanimations .square{
    width:50%;
}
.video-wrapper iframe{
    max-width:100%;
}

@media only screen and (min-width: 782px) {
        iframe{ 
            max-height:518px !important;
            margin-bottom: 2rem;
        }
}

.graphicanimation .gif{
    margin-bottom:4rem;
}

.video-big iframe{
    max-width:100%;
    max-height:348px !important;
}

@media only screen and (min-width: 782px) {
    .video-big iframe{
       /* iframe{ max-width:642px;}*/
        max-width:912px;
        max-height:518px  !important;
    }
}

.video-wrapper .column-left, .video-wrapper .column-right {
    width:50%;
}
.video-wrapper img{
    width:100%;
}
.column-left, .column-right{
    width:100%;
    margin-top:1rem;
}

@media only screen and (min-width: 782px) {
    .column-left, .column-right{
        width:48%;
        margin-top:2rem;
    }
    .column-left{
        margin-right:4%;
        float:left;
    }
    .column-left.bottom-pic{
        margin-bottom:6rem;
    }
    .column-right{
        float:right;
        margin-top:2rem;
    }
}

.image-big{
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.image-big img {
    height: auto;
    width: 100%;
}
.pioniers .image-big img{
    margin-top:0;
}
@media only screen and (min-width: 782px) {
    .squares{
        display: flex;
    }
    .square{
        width:46%;
    }
    .square-small{
        margin-left: 8%;
        min-height: 400px;
        width: 46%;
    }
    .boz .square{
        width:30%;
    }
    .pionier .double .square{
        margin-top:2rem;
        width:35.5%;
    }
    .boz .square:nth-of-type(2), .boz .square:nth-of-type(3){
        margin-left: 4%;
    }
    .pioniers .wide .square{
        margin-top:2rem;
        width: 75%;
    }
    .pioniers .wide .square:nth-of-type(2), .pioniers .wide .square:nth-of-type(3){
        width: 21%;
    }
    .hzt-page .wide {
        margin-top:4rem;
    }
    .hzt-page .wide .square{
        width:70%;
    }
    .hzt-page .wide .square:nth-of-type(2){
        width:30%;
        margin-left:2rem;
    }
    .image-big{
        margin-top: 0;
    }
    .pioniers .image-big:nth-of-type(2){
        margin-bottom:4rem;
    }
}
h4{
    font-size: 22px;
}
.description-big{
    width: 100%;
    color: #fff;
    font-size: 20px;
    line-height: 24px;    
    margin-bottom: 2rem;
}
@media only screen and (min-width: 782px) {
    .description-big{
        width: 80%;
        font-size: 22px;
        line-height: 28px;    
    }   
}
.image-horizontal{
    margin: 2rem 0;
}
.image-horizontal img{
    width:100%;
}
img {
    max-width:100%;
}
.vertical-container{
    margin-top:2rem;
    display:block;
}
.vertical-image{
    width:100%;
}
.mobile-up{
    margin-top:-60px;
}
.anim.top{
    margin-top:10px;
}
@media only screen and (min-width: 782px) {
    .up{
        margin-top:0;
    }
    .vertical-image.right{
        position:absolute;
        right:0;
        z-index:-1;
    }
    .vertical-image.center{
        width:60%;
        margin-left:10%;
        margin-top:10%;
    }
    .vertical-image.right{
        top:50%;
    }
    .column-right.top, .top{
        margin-top:35%;
    }
    .column-right.up{
        position:relative;
        z-index:-1;
        margin-top:-10%;
    }
    .small{
        width:60%;
    }
    .small-medium{
        width:80%;
    }
    .column-left.up, .column-right.up.high{
        position:relative;
        z-index:-1;
        margin-top:-20%;
    }
    .column-right.up.z, .column-left.up.z, .z{
        z-index:1;
    }
    .column-left.third{
        width:30%;
    }
    .reflect img{
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter: "FlipH";
    }
}

.pioniers .vertical-image{
    width: 88%;
    margin-right: 6%;
    margin-left: 6%;
}
.boz.pioniers .vertical-image{
    width:100%;
    background-color:#004699;
}
.boz .vertical-image{
    background-color:#acc8fe;
}
.boz.dustin .vertical-image{
    background-color:#ebf7fc ;
}
.boz .vertical-image img{
    display:block;
    margin:0 auto;
}
@media only screen and (min-width: 782px) {
    .boz .vertical-image, .boz.pioniers .vertical-image, .boz.dustin .vertical-image{
        background-color:inherit;
    }
}
.pioniers .vertical-image {
    background-image:none;
}
.boz.pioniers .frame{
    margin-bottom:3rem;
}
.vertical-text{
    width: 88%;
    margin-right: 6%;
    margin-left: 6%;
    margin-top: 2rem;
}
.vertical-text h3{
    font-size: 22px;
    line-height: 28px;
}
.vertical-text p{
    font-size:18px;
    margin-bottom:1rem;
}
.project-button{
    margin-top: 4px;
    border: 2px solid #FFF;
    text-decoration: none;
    display: inline-block;
    padding: 2px 16px 4px;
}
.project-button{
    margin-bottom:4rem;
}
h3{ 
    font-size:22px; 
    line-height:28px;
}


@media only screen and (min-width: 782px) {
    .image-horizontal img{
        width:50%;
    }
    .vertical-container{
        margin-top:4rem;
        display:flex;
        width:100%;
    }
    .boz .vertical-container{
        margin-top:8rem;
    }
    .boz .frame{
        margin-top:8rem;
        height:auto;
    }
    .boz.pioniers .frame{
        margin-top:3rem;
        margin-bottom:4rem;
        height:100vh;
    }
    .boz .pioniers-frame{
        margin-top:0;
        position:relative;
    }
    .boz .frame .inner{
        height:100vh;
        width:50%;
        background-color:#acc8fe;
        position:absolute;
        z-index:-1;
    }
    .boz .pioniers-frame .inner{
    margin-top:20%;
    width: 64vw;
    height: 100vh;
    /*background-color: #ffaead;*/
    /*background-color:#EF9998;*/
    background-color:#000;
    -webkit-transform: none;
    -moz-transform: none;
    transform: none;
    border-radius: 0 100% 0 0;
    }
    .boz.dustin .inner{
        background-color:#F3F6F7;
    }
    .vertical-image{
        width:55%;
        max-height:1vh;
    }
    .boz .vertical-image{
        width:100%;
    }
    .dustin .vertical-image{
        width:90%;
    }
    .boz .vertical-image img{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 40%; 
        padding-top:15vh;
        margin:0 auto;
        display:block;
    }
    .boz.pioniers .vertical-image img {
        padding-top:11vh;
    }
    .boz .pioniers-frame .vertical-image img{
        margin-left:4rem;
        width:25%;
    }
    .boz.dustin .vertical-image img{
        margin-left:10%;
    }
    .vertical-text{
        width: 40%;
        margin-left: 8%;
        margin-right: 7%;
        margin-top:10rem;
    }
    .boz .vertical-text{
        width: 28%;
        margin-left: 66%;
        margin-right: 0;
        margin-top: 16vh;
    }
    .boz.dustin .vertical-text{
        margin-left:60%;
    }
    .vertical-text h3, h3{
        font-size :40px;
        line-height: 60px;
    }
    .project-button{
        margin-bottom: 0;
    }
    .middle{
        margin: 3rem 0 4rem 0;
    }
    .pioniers .middle {
        margin-bottom: 2rem;
    }
}
@media only screen and (min-width: 1280px) {
    .boz .vertical-image img,.pioniers.dustin .vertical-image img{
        width: 36%; 
    }
    .boz.pioniers .vertical-image img{
        width:25%;
    }
    .pioniers .vertical-image img{
        width: 22%;
    }
}
.next{
    font-family: FuturaPTbook, sans-serif;
    text-decoration:none;
    position: fixed;
    left: auto;
    top: auto;
    right: 0;
    bottom: 0;
    width: auto;
    text-transform:uppercase;
    font-weight: 500;
    letter-spacing: 4px;
    padding: 6px 16px 8px 10px;
    background-color:black;
}
@media only screen and (min-width: 782px) {
    .next{
        bottom:16px;
        padding:0 0;
        background-color:inherit;
        right:16px;
    }
}
@media only screen and (min-width:1025px) {
   .next{
    padding-bottom:4px;
   }
  .next:after {    
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #fff;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  .light .next:after{
    background:#000;
  }

  .next:hover:after { 
    width: 100%; 
    left: 0; 
  }
}
.next img {
    width: 11px;
    min-width: 11px;
    margin-top: -1px;
    margin-left:4px;
}
.light .next img{
    filter: invert(100%);
}
.about .vertical-image{
    background-image: url(../../images/linda-hoekstra.png);   
    background-size: cover; 
    height: 79vh;
    min-height:79vh;
    background-position:center center;
}

@media only screen and (min-width: 1025px){
    .about-grid{
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }
    .about .vertical-image { 
        width:100%;
        height:100vh;
        max-height: 100vh;
        -ms-grid-row: 1;
        grid-row-start: 1;
        -ms-grid-row-span: 5;
        grid-row-end: 6;
        -ms-grid-column: 1;
        grid-column-start: 1;
        -ms-grid-column-span: 2;
        grid-column-end: 3;
    }
    .grid-3 {
        display: -ms-grid;
        display: grid;
        grid-auto-columns: 1fr;
        width: 50%;
        min-width: auto;
        margin-top: 0px;
        grid-column-gap: 0px;
        grid-row-gap: 0;
        -ms-grid-columns: 1fr 3fr 1fr;
        grid-template-columns: 1fr 3fr 1fr;
        -ms-grid-rows: minmax(81px, 1.5fr) auto auto auto auto;
        grid-template-rows: minmax(81px, 1.5fr) auto auto auto auto;
    }
}


.about .marquee {
    margin: 3rem auto;
    position: relative;
    overflow: hidden;
  --marquee-width: 100vw;
  --offset: 12vw;
  --move-initial: calc(-12.5% + var(--offset));
  --move-final: calc(-62.5% + var(--offset));
  --item-font-size: 20vw;
}
.about .marquee__inner {
    width: fit-content;
    display: flex;
    position: relative;
    transform: translate3d(var(--move-initial), 0, 0);
    animation: marqueeproject 3s linear infinite;
    animation-play-state: running;
    display:block;
    text-align:center;
}
.about .marquee span {
    font-size:20vw;
    padding: 0 4px;
    line-height:1;
    font-family:Neon;
    color:#83f3d2; /*00FF00.  #46ff7e*/
    z-index:999;
}
.about .vertical-image{
    width:100%;
}

@media only screen and (min-width: 1025px){

   .about .marquee {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    height:100vh;
    --marquee-width: auto;
    --offset:15em;
    --move-initial: calc(-12.5% + var(--offset));
    --move-final: calc(-62.5% + var(--offset));
    min-width:10em;
    }

    .about .marquee__inner {
        transform: translate3d(0, var(--move-initial), 0);
        animation: marquee 5s linear infinite;
        animation-play-state: paused;
    }

    .about .marquee span {
     font-size:10em;
      padding: 5px 2vw;
      line-height:0.749;
      font-family:Neon;
      color:#83f3d2; /*00FF00.  #46ff7e*/
      z-index:999;
      display:block;
    }

    .about .marquee:hover .marquee__inner {
        animation-play-state: running;
    }
    .about .vertical-text{
        width: 32%;
    }

}



@keyframes marquee {
    0% {
         transform: translate3d(0, var(--move-initial), 0);
    }
    100% {
        transform: translate3d(0, var(--move-final), 0);
    }
}
@keyframes marqueeproject {
  0% {
    transform: translate3d(var(--move-initial), 0, 0);
  }

  100% {
    transform: translate3d(var(--move-final), 0, 0);
  }
}

nav{
    display:none;
}
