nav{
    width: 100%;
    height: 40px;
    position:fixed !important;
}
nav ul {
  list-style-type: none;
  margin: 0 2px 0 0;
  padding: 0;
  display:table;
  float:right;
}
nav ul li {
  display: inline-block;
  display:table-cell;
}
nav li a {
  position:relative;
  display: inline-block;
  margin: 0 8px;
  padding: 10px 0 6px 0;
  color:#FFFFFF;
  text-decoration:none;
  font-family: FuturaPT, sans-serif;
  font-weight: 500;
  letter-spacing: 4px;
  text-transform:uppercase;
  font-size: 14px;
}
nav li a.active { 
  padding-bottom:4px;
  border-bottom:2px solid white;
}
.light nav li a{
  color:#000;
}
.light nav li a.active{
  border-bottom:2px solid black;
}
@media only screen and (min-width:1080px) {
  nav li a:after {    
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #FFFFFF;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  .light nav li a:after{
    background:#000;
  }

  nav li a:hover:after { 
    width: 100%; 
    left: 0; 
  }
  nav li a.active:hover:after{
    width:0;
    transition: width 0s ease 0s, left 0s ease 0s;
  }
}
nav li.active a:after { 
  width: 100%; 
  left: 0; 
}